import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { SearchTextField } from '@data-c/ui'

import ContentContainer from 'components/Container/components/ContentContainer'
import HeaderContainer from 'components/Container/components/HeaderContainer'
import PerfilAvatar from 'components/PerfilAvatar'
import EmptyData from 'components/EmptyData'
import Container from 'components/Container'
import PageTitle from 'components/PageTitle'

import useCliente, { ClienteModel } from 'hooks/queries/useCliente'
import ClientesCardInfo from './components/ClientesCardInfo'

export default function Clientes() {
  const navigate = useNavigate()

  const [query, setQuery] = useState('')

  const { useQueryObterClientes } = useCliente()
  const { data: clientes, isLoading, error } = useQueryObterClientes(query)

  function handleClick(cliente: ClienteModel) {
    navigate(`/clientes/cliente/${cliente?.id || ''}`)
  }

  return (
    <Container fullHeight={false}>
      <HeaderContainer
        containerSx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <PageTitle title="Clientes" disablePadding />
          <PerfilAvatar />
        </Stack>

        <SearchTextField
          onSearch={(query) => setQuery(query)}
          placeholder="Pesquisar clientes"
          autoFocus={false}
        />
      </HeaderContainer>

      <ContentContainer
        isLoading={isLoading}
        error={error}
        disablePadding
        customSx={{ margin: '112px 0 88px' }}
      >
        {clientes?.data && clientes?.data?.length > 0 ? (
          clientes?.data?.map((cliente) => (
            <ClientesCardInfo
              key={cliente.id}
              cliente={cliente}
              onClick={(cliente) => handleClick(cliente)}
            />
          ))
        ) : (
          <EmptyData description="Nenhum registro foi encontrado" />
        )}
      </ContentContainer>
    </Container>
  )
}
