import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { ProdutoModel } from 'hooks/queries/useProduto'
import { NFModel } from 'hooks/queries/useNF'
import useAtualizarValoresItemPedido from 'hooks/queries/business/useAtualizarValoresItemPedido'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'

import ProdutoCardInfo from '../ProdutoCardInfo'

interface TabProdutosListaProps {
  produtos: ProdutoModel[]
  nf?: NFModel
  isLoading: boolean
  error: AxiosError | null
}

export default function TabProdutosLista(props: TabProdutosListaProps) {
  const { produtos, nf, isLoading, error } = props

  const navigate = useNavigate()
  const existeNFPagamento = (nf?.nfPagamentos?.length || 0) > 0
  const { atualizarValoresItemPedido } = useAtualizarValoresItemPedido()

  return (
    <Container fullHeight={false} overflow="visible">
      <ContentContainer
        disablePadding
        isLoading={isLoading}
        error={error}
        customSx={{
          marginBottom: existeNFPagamento ? 34 : 26,
          marginTop: '170px',
        }}
      >
        {produtos.map((produto) => {
          const produtoAtualizado = atualizarValoresItemPedido(produto, nf)
          return (
            <ProdutoCardInfo
              key={produto.id}
              item={produtoAtualizado}
              onClick={() => {
                navigate(`/NF/${nf?.id}/produtos/produto/${produto?.id}`)
              }}
            />
          )
        })}
      </ContentContainer>
    </Container>
  )
}
