import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { NFModel } from 'hooks/queries/useNF'

import Container from 'components/Container'
import ContentContainer from 'components/Container/components/ContentContainer'

import EmptyCart from './components/EmptyCart'
import ProdutoCardInfo from '../ProdutoCardInfo'

interface TabItensAdicionadosProps {
  nf?: NFModel
  isLoading: boolean
  error: AxiosError | null
}

export default function TabItensAdicionados(props: TabItensAdicionadosProps) {
  const { nf, isLoading, error } = props

  const navigate = useNavigate()
  const existeItensAdicionados = nf?.nfItems && nf.nfItems.length > 0

  return (
    <Container fullHeight={false} overflow="visible">
      <ContentContainer
        disablePadding
        isLoading={isLoading}
        error={error}
        customSx={{
          marginBottom: existeItensAdicionados ? 34 : 20,
          marginTop: '170px',
        }}
      >
        {existeItensAdicionados ? (
          nf?.nfItems?.map((nfItem) => {
            return (
              <ProdutoCardInfo
                key={nfItem?.id}
                item={{
                  ...nfItem,
                  nome: nfItem?.produto?.nome || '',
                  estoque: nfItem?.produto?.estoque,
                  nfItemId: nfItem?.id,
                  codigo: nfItem?.produto?.codigo,
                  nf,
                }}
                onClick={() => {
                  navigate(
                    `/NF/${nfItem?.nfId}/produtos/produto/${nfItem?.produtoId}`,
                  )
                }}
              />
            )
          })
        ) : (
          <EmptyCart />
        )}
      </ContentContainer>
    </Container>
  )
}
